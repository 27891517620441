<template>
    <div class="library-wrapper">
        <div class="library-wrapper-top">
            <div class="top-title">我的选品库</div>
            <div class="btn-blue" @click="returnIndex()">返回</div>
        </div>
        <div class="library-container" v-if="listData.length > 0">
            <el-scrollbar class="library-list-scrollbar">
                <div class="library-list">
                    <div class="list-item" v-for="(item,index) in listData" :key="`listData${index}`"
                         @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)">
                        <div class="list-item-top" >
                            <span class="list-item-tile ">{{item.title}}</span>
                            <i class="el-icon-close" v-show="item.showDel" @click="deleteGroup"></i>
                        </div>
                        <div class="list-item-center">
                            <span class="goods-num">商品数量：<em>{{item.number}}</em></span>
                            <div class="list-item-goods-box" v-if="item.goodsList.length>0">
                                <div  v-for="(item2,index) in item.goodsList" :key="`goodsList${index}`">
                                    <div class="list-item-goods-img" v-if="index<4" >
                                        <img :src="item2.goodsImg"  alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="null-goods" v-else>
                                <span  style="text-align: center">暂无商品</span>
                            </div>
                        </div>
                        <div class="list-item-bottom" >
                            <div class="btn-title" v-if="item.goodsList.length>0" @click="massPromotion()">批量推广</div>
                            <div class="disabled" v-else>批量推广</div>
                        </div>


                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="library-container" v-else>
            <div class="nll-data"><img src="../../../assets/img/image/null-fz.png" alt=""></div>
        </div>

        <el-dialog title="批量推广数据已生成" :visible.sync="massPromotionDialog" :close-on-click-modal="false" custom-class="centerDialog" width="643px">
            <div class="next-dialog-body">
                <p>请导出Excel，并投放到您的推广位中</p>
                <ul>
                    <li>1、淘口令只有<span class="color-brand">30天</span>的有效期，过期失效需要重新获取。</li>
                    <li>2、如您推广的是航旅的当面付、火车票或者理财保险类商品，将无法获得佣金。</li>
                    <li>3、若订单使用红包或购物券后佣金有可能支付给红包推广者，如您是自推自买，请勿使用红包及购物券。</li>
                    <li><span class="color-brand">4、请注意：苹果设备(iOS14及以上版本手机或iPad等)，手机淘宝将按照完整版淘口令 读取并识别，否则无法打开。如需更改淘口令内文案、url等内容，请务必先验证更改后 的淘口令在手淘可被识别打开！其他iOS版本和所有安卓系统，仍可用短淘口令(示例 ￥ lmeeeeee￥)推广。</span></li>
                    <li>5、淘口令基础上可增加或优化营销文案，若用户复制时有营销文案，对于使用苹果设备(iOS14及以上版本手机或iPad等)的用户，淘口令必须放在营销文案的前面。</li>
                </ul>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" class="color-brand-btn" @click="exportExcel()">导出Excel</el-button>
                <el-button size="medium" @click="massPromotionDialog = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "MySelectionLibrary",
        data(){
            return{
                listData:[
                    {
                        title:'这是测试分组',
                        number:20,
                        goodsList:[
                            { goodsImg:require('../../../assets/img/ceshi/2.jpg')},
                            { goodsImg:require('../../../assets/img/ceshi/2.jpg')},
                            { goodsImg:require('../../../assets/img/ceshi/2.jpg')},
                            { goodsImg:require('../../../assets/img/ceshi/2.jpg')},
                            { goodsImg:require('../../../assets/img/ceshi/2.jpg')},
                        ],
                        showDel:false
                    },
                    {
                        title:'这是测试分组',
                        number:20,
                        goodsList:[],
                        showDel:false
                    },
                ],
                massPromotionDialog:false,//批量推广数据弹窗
            }
        },
        methods:{
            mouseenter(index){
                this.listData[index].showDel = true;
            },
            mouseleave(index){
                this.listData[index].showDel = false;
            },
            // 返回
            returnIndex(){
                this.$router.go(-1)
            },
            // 批量推广
            massPromotion(){
                this.massPromotionDialog=!this.massPromotionDialog;
            },
            //导出
            exportExcel(){

            },
            // 删除组
            deleteGroup(){
                this.$confirm('确定要删除这个选品分组吗？ 删除后该分组将从选品库移除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'blue-btn',
                }).then(() => {
                    this.$httpStudent.axiosGetBy( (res) => {
                        if (res.code === 200) {
                        } else {
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .library-wrapper{
        display: flex;
        flex-direction: column;
        height: 100%;
        .library-wrapper-top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .top-title{
                color: #444;
                font-size: 18px;
            }
            .btn-blue {
                width:80px;
                background: #574DED;
                border-radius: 4px;
                color: #fff;
                text-align: center;
                line-height: 32px;
                cursor: pointer;
            }
        }
        .library-container{
            display: flex;
            flex-direction: column;
            background: #fff;
            margin-top: 20px;
            border-radius: 10px;
            padding: 30px 26px;
            box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.0);
            height: calc(100vh - 212px);
            .library-list-scrollbar{
                height: 100%;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }
            .library-list{
                display: flex;
                flex-wrap: wrap;
                .list-item{
                    border: 1px solid #DCDCDC;
                    border-radius: 4px;
                    margin: 0 20px 20px 0;
                    /*width: calc(20% - 20px);*/
                    width: 300px;
                    .list-item-top{
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;
                        box-sizing: border-box;
                        .list-item-tile{
                            font-size: 16px;
                            color: #444;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            overflow: hidden;
                        }
                        i{
                            height: 20px;
                            width: 20px;
                            line-height: 20px;
                            font-size: 16px;
                            cursor: pointer;
                            &:hover{
                                color:#fE4800;
                            }
                        }
                    }
                    .list-item-center{
                        padding: 10px;
                        box-sizing: border-box;
                        height: 135px;
                        .goods-num{
                            font-size: 14px;
                            color: #666;

                            em{
                                color:#fE4800;
                                font-style:normal
                            }
                        }
                        .list-item-goods-box{
                            display: flex;
                            .list-item-goods-img{
                                width: 56px;
                                height: 56px;
                                margin: 20px 18px 20px 0;
                                img{
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                        }
                        .null-goods{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 96px;
                            span{
                                background: #EDEDF0;
                                border-radius: 2px;
                                width: 100px;
                                height: 40px;
                                line-height: 40px;

                            }
                        }
                    }
                    .list-item-bottom{
                        height: 50px;
                        line-height: 50px;
                        .btn-title{
                            text-align: center;
                            font-size: 14px;
                            color: #444;
                            cursor: pointer;

                            &:hover{
                                background: #FE4800;
                                color: #fff;
                            }
                        }
                        .disabled{
                            text-align: center;
                            color: #999;
                            cursor: not-allowed;
                        }
                    }
                    &:nth-of-type(5n){
                        margin-right: 0;
                    }
                }
            }
            .nll-data{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }
        .next-dialog-body{
            .color-brand{
                color: #FE4800;
            }
        }

        .dialog-footer{
            text-align: center;
            .color-brand-btn {
                color: #FFF;
                background-color: #FE4800;
                border-color: #FE4800;
                &:hover,&:focus{
                    background: #ff822e;
                    border-color: #ff822e;
                    color: #FFF;
                }
            }
        }
    }
</style>